import {SourceBaseModel} from '@matchsource/models/source';
import {HlaExtendedModel, HlaMapModel, HlaModel} from '@matchsource/models/hla';
import {PaginatedDataSetModel} from '@matchsource/models/core';
import {OplCordSimpleModel, OplCordSourceModel} from '@matchsource/models/opl';
import {LocationDetails} from '@matchsource/models/location';

import {OrderableStatusModel} from '@matchsource/models/order-shared';

export interface CordMatch {
  alleleLevelMatchProbability8Values: number[];
  alleleLevelMatchProbability6Values: number[];
  alleleLevelMatchProbability10List: string[];
  alleleLevelMatchProbability8List: string[];
  alleleLevelMatchProbability6List: string[];
  alleleLevelMatchProbability10: string;
  alleleLevelMatchProbability8: string[];
  alleleLevelMatchProbability6: string;
  dpb1TCEMatch: string;
  ccr5?: string;
  cd34FcWeight: string | number;
  tncWeight: string | number;
  matchingCategory_Xf8: string;
  matchingCategory_Xf6: string;
  matchingCategory: string;
  hla: HlaModel[];
  hlaMap: HlaMapModel;
  noTypingAvailable: boolean;
}

export interface CordBaseModel extends SourceBaseModel {
  availabilityDate: MsApp.DateString;
  birthDate: MsApp.DateString;
  cd34FCWeight: number;
  cd34FrozenCell: number;
  cd34TncRatio: string;
  finalProdVol: number;
  hgb: string;
  hgbTranslated: string;
  licenseStatus: string;
  matching: CordMatch;
  matCatg6: number;
  matCatg8: number;
  nrbc: number;
  pid: MsApp.Guid;
  seg: number;
  tncFrozen: number;
  tncWeight: number;
  type: 'cord';
  plasmaReduced: string;
}

type ModifiedCordBaseModel = Omit<CordBaseModel, 'type' | 'matCatg6' | 'matCatg8'> & LocationDetails;

export interface CordDetails extends ModifiedCordBaseModel {
  processingMethodType?: string;
  fractionCount: number;
  eligibility: string;
  type: 'NMDP' | 'COOP_MEMBER' | 'NON_MEMBER';
  additivePreProc: number;
  fungalCulture: string;
  isNMDP: boolean;
  bagType?: string;
  nccPreProc?: number;
  cultureDate: string;
  nccPostProc?: number;
  nrbcPostProc?: number;
  cd34PostProc?: number;
  cd34PercentPostProc?: number;
  cd34InMccPercentPostProc?: number;
  cd34FrozenViabilityProc?: number;
  mccInTncPercentPostProc?: number;
  cd3PostProc?: number;
  bacterialCulture: string;
  bloodType?: string;
  localStatus: string;
  primaryRaceCode: string;
  productModifications?: string;
  cd34Weight?: number;
  eligibilityCode: string;
  freezeDate: string;
  eligibilityDeterminationReasons: string;
  processingMethods?: string;
  isbtDin: string;
  monoFrozenNccPostProc: number;
  volumeFrozen?: number;
  volumePreProc?: number;
  ncbiFunded: string;
  rbcReduced: string;
  tncPostProc?: number;
  numberOfCordBloodUnitBags?: string;
  tncPreProc?: number;
  processingDate: string;
  nrbcPercentPostProc?: number;
  segmentCount: number;
  bagId: string;
  isEmdisCoop: boolean;
  regCbb: string;
  collectionDate: string;
  ptr: MsApp.Dictionary<HlaExtendedModel>;
  totalVolumePreProc: number;
  countryDescription: string;
  location: string;
  racesParsed: MsApp.KeyValuePair<string, string[]>[];
  matCatg6: string;
  matCatg8: string;
  cureReady: boolean;
  cd34TncRatio: string;
  ccr5Genotype?: string;
}

export interface CordLookupModel {
  guid: MsApp.Guid;
  nmdpId: string | null;
  localId: string;
  bpGuid: MsApp.Guid;
  emdisId: string | null;
  status: string;
  displayId: string;
  categoryCode: string;
}

export type CordLookupDataSetModel = PaginatedDataSetModel<CordLookupModel>;

export interface CordRbcAndPlasmaReducedModel {
  bagType?: string;
  fractionCount?: number;
  numberOfCordBloodUnitBags?: number;
  plasmaReduced?: boolean;
  processingMethodType?: string;
  processingMethods?: string;
  productModifications?: string;
  rbcReduced?: boolean;
}

export type GetRbcAndPlasmaReducedResponseModel = MsApp.Dictionary<CordRbcAndPlasmaReducedModel>;

export interface CordListModel {
  id: MsApp.Guid;
  pid: string;
  ref: number;
  grid: string;
  localId: string;
  status: string;
  statusClass: string;
  statusLabel: string;
  statusOverride?: string;
  age: number;
  sex: string;
  rh: string;
  bloodTypeCode: string;
  bloodTypeRh: string;
  cmv: string;
  cmvDate: string;
  weight: string | number;
  race: string;
  races: any;
  ethnicityCode: string;
  ethnicity: string;
  regDate: string;
  bpGuid: string;
  bpId: string;
  bpName: string;
  doingBusinessAsName: string;
  country: string;
  countryCode: string;
  previousCT: string;
  lastContactDate: string;
  contactType: string;
  productType: string;
  list: string;
  availabilityDate: string;
  tncWeight: number | string;
  cd34FCWeight: string | number;
  tncFrozen: number;
  cd34FrozenCell: number;
  licenseStatus: string;
  seg: number;
  hgb: string;
  hgbTranslated: string;
  nrbc: number;
  matCatg8: number;
  matCatg6: number;
  finalProdVol: number;
  birthDate: string;
  type: 'cord' | 'bdp';
  plasmaReduced: string;
  orderableStatus?: OrderableStatusModel;
  orderable: boolean;
  orderableReasons: string[];
  orderableForCmOnly: boolean;
  orderableSameDayOrder: boolean;
  orderableRecipientClosed: boolean;
  duplicateRecipient: boolean;
  isWmda: boolean;
  guidPart: string;
  guid: MsApp.Guid;
  pendingThisRequest: boolean;
  pendingAnotherRequest: boolean;
  raceEth: string;
  list_bpId: string;
  cordCenterIdentifier: string;
  DOB_SEX: string;
  M_CAT_GROUPED: string;
  tncWeight_cd34FCWeight: string;
  tncWeight_cd34FCWeight_low: boolean;
  tncWeight_cd34FCWeight_unknown: boolean;
  tncFrozen_cd34FrozenCell: string;
  lastContactDate_contactType: string;
  final_prod_vol_ABO_RH: string;
  previousCT_licensure: string;
  hgb_nRBC: string;
  nrbcFormatted: string;
  hgbFormatted: string;
  availabilityDate_of_seg: string;
  dpb1TCEMatch_ccr5: string;
  populationCode: string;
  eligibilityCode: string;
  rbcReduced: string;
  rbcReducedRaw: string;
  matching: any;
  bpRestricted: string;
  displayId?: MsApp.Guid;
  cted?: boolean;
  cureReady?: boolean;
  isRestrictedView?: boolean;
  ebsMissing?: boolean;
  bLeader: string;
  cd34TncRatio?: string;
}

export interface CordWideListModel extends CordListModel, OplCordSimpleModel, OplCordSourceModel {}

export interface CordSearchParams {
  searchTerm: string;
}

export const HGB_MAPPING_CONFIG = {
  NM: 'CORD.HGB.NM',
  HT: 'CORD.HGB.HT',
  H: 'CORD.HGB.H',
  ASC: 'CORD.HGB.ASC',
  ATT: 'CORD.HGB.ATT',
  BTT: 'CORD.HGB.BTT',
  SBT: 'CORD.HGB.SBT',
  HD: 'CORD.HGB.HD',
  ASD: 'CORD.HGB.ASD',
  MT: 'CORD.HGB.MT',
  D: 'CORD.HGB.D',
  U: 'CORD.HGB.U',
  CD: 'CORD.HGB.CD',
  NC: 'CORD.HGB.NC',
};
export const CORD_TYPE = {
  NMDP: 'NMDP',
  COOP_MEMBER: 'COOP_MEMBER',
  NON_MEMBER: 'NON_MEMBER',
};
